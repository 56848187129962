<template>
  <div>
    <div class="flex items-center pb-1 mobile:justify-between mobile:flex-wrap">
      <h2>Minu projektid</h2>

      <div
        class="flex relative mobile:justify-center ml-3 items-center justify-center mobile:pb-2"
      >
        <div class="flex items-center justify-center">
          <div
            class="items-center flex flex-row cursor-pointer"
            @click="menuActive = !menuActive"
          >
            <span
              v-if="statusFilter"
              v-html="parseProjectStatus(statusFilter.status)"
            />
            <span v-if="!statusFilter">Vali staatus</span>
            <span class="typcn typcn-arrow-sorted-down mx-3"></span>
          </div>

          <div class="ml-2">
            <button
              class="new-button-danger"
              v-if="statusFilter"
              @click="selectStatus(null)"
            >
              <span class="icon"
                ><img src="/bc21/trash.svg" class="h-5 filter-to-white"
              /></span>
              <span class="label">Tühjenda</span>
            </button>
          </div>
        </div>

        <div class="status-menu" v-if="menuActive">
          <span
            v-for="status in statusFilters"
            :key="status.status"
            class="status-menu-item cursor-pointer"
            v-html="parseProjectStatus(status.status)"
            @click="selectStatus(status)"
          ></span>
        </div>
      </div>

      <div
        class="ml-auto mobile:ml-0 mobile:w-full flex items-center bg-offwhite rounded-full duration-100 mobile:mb-3"
        :class="focusSearch ? 'shadow-outline' : 'shadow'"
      >
        <div class="w-1/12 px-1">
          <span class="">
            <img src="/bc21/search.svg" class="h-4 w-4" />
          </span>
        </div>
        <input
          type="text"
          v-model="searchQuery"
          @input="debounceSearch"
          placeholder="Otsi projekti"
          class="shadow-none focus:shadow-none hover:shadow-none"
          @focus="focusSearch = true"
          @blur="focusSearch = false"
        />
      </div>
    </div>
    <div
      class="flex flex-col"
      v-if="projectsLoaded && projects && projects.results.length > 0"
    >
      <router-link
        :to="`/projects/${item.uuid}`"
        v-for="item in projects.results"
        :key="item.uuid"
      >
        <draft-description :data="item"> </draft-description>
      </router-link>

      <paginator
        v-if="projects.count > 1"
        :page="page"
        :count="projects.count"
        @pageChange="pageChange"
      ></paginator>
    </div>
    <div
      v-if="
        projectsLoaded &&
          $store.state.companyData.companies &&
          $store.state.companyData.companies.length === 0 &&
          !projects
      "
    >
      <span
        >Teie kontoga ei ole seotud ühtegi projekti. Projektide loomiseks ja
        Gewodo ärihalduse tööriistade kasutamiseks vajuta
        <router-link to="/company/new" class="hover:text-green font-medium"
          >siia.</router-link
        ></span
      >
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import debounce from "lodash/debounce";
import StatusParser from "@/assets/mixins/StatusParser";
import Paginator from "@/components/reusable/Paginator";
import DraftDescription from "@/components/reusable/DraftDescription";

const statusFilters = [
  {
    text: "Aktiivne",
    status: 2
  },
  {
    text: "Käigus",
    status: 3
  },
  {
    text: "Probleem",
    status: 4
  },
  {
    text: "Lõpetatud",
    status: 5
  },
  {
    text: "Kinnitatud",
    status: 6
  }
];
export default {
  data() {
    return {
      projects: [],
      projectsLoaded: false,
      page: 1,
      focusSearch: false,
      searchQuery: "",
      statusFilter: null,
      statusFilters: [],
      menuActive: false
    };
  },
  components: {
    Paginator,
    DraftDescription
  },
  mixins: [RequestHandler, StatusParser],
  mounted() {
    this.statusFilters = statusFilters;

    this.retrieveData();
  },
  methods: {
    pageChange(e) {
      this.page = e;
      this.retrieveData();
    },
    selectStatus(status) {
      this.statusFilter = status;
      this.menuActive = false;
      this.retrieveData();
    },
    debounceSearch: debounce(function() {
      this.retrieveData();
    }, 500),
    retrieveData() {
      if (this.$store.state.userData.user.is_email_verified) {
        this.apiRequest(
          "notes/drafts/client/?page=" +
            this.page +
            (this.searchQuery ? "&s=" + this.searchQuery : "") +
            (this.statusFilter ? "&state=" + this.statusFilter.status : ""),
          "get",
          true
        ).then(res => {
          if (res.status === 200) {
            this.projects = res.data;
            this.projectsLoaded = true;
          }
        });
      } else {
        this.projects = [];
        this.projectsLoaded = true;
      }
    }
  }
};
</script>
